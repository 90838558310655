import React from 'react'

const Header = ({ heading }) => {
    return (
        <header className="header valign bg-img" data-overlay-dark="5" data-stellar-background-ratio="0.5"
            style={{
                minHeight: '50vh',
                backgroundImage: 'url(/img/5.jpg)'
            }}
        >

            <div className="container">
                <div className="row">
                    <div className="full-width text-center caption mt-30">
                        <h1>{heading}</h1>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header