import React from 'react'

const WhatWeDo = () => {
    return (
        <section className="hero section-padding bg-gray" data-scroll-index="1">
            <div className="container">
                <div className="row">

                    <div className="offset-lg-1 col-lg-10">

                        <div className="intro text-center mb-80">
                            <h5>What We Do</h5>
                            <h3>We love what we do & we do it with passion!</h3>
                            <p>
                                We are a social media and digital marketing agency in Dubai created to help individuals, freelancers, and companies to undertake and succeed on the internet.
                            </p>
                        </div>

                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="item text-center mb-md50">
                            <div className="img-overlay bg-img" data-overlay-dark="7" data-background="/img/1.jpg"></div>
                            <span className="icon ti-desktop"></span>
                            <h6>Web Design & Development</h6>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="item text-center mb-md50">
                            <div className="img-overlay bg-img" data-overlay-dark="7" data-background="/img/2.jpg"></div>
                            <span className="icon ti-shield"></span>
                            <h6>Search Engine Optimization</h6>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="item text-center mb-sm50">
                            <div className="img-overlay bg-img" data-overlay-dark="7" data-background="/img/3.jpg"></div>
                            <span className="icon ti-infinite"></span>
                            <h6>Digital Marketing</h6>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="item text-center">
                            <div className="img-overlay bg-img" data-overlay-dark="7" data-background="/img/4.jpg"></div>
                            <span className="icon ti-stats-up"></span>
                            <h6>Mobile App Development</h6>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default WhatWeDo