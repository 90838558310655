import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider, createTheme } from '@mui/material';

const Product = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const theme = createTheme({
        typography: {
            fontFamily: 'Poppins',
        },
    })


    return (
        <>
            <section className="video section-padding" data-scroll-index="1">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 valign">
                            <div className="content mb-md50">
                                <h5>Welcome to J. Marketing Services,</h5>
                                <h3>Premium Membership</h3>
                                <div className="custom-text mb-10">
                                    Develop Creative Digital Future
                                </div>
                                <p>
                                    At J. Marketing Services, we don't just design websites; we craft immersive digital experiences that leave a lasting impression.
                                    In the bustling realm of the internet, we stand as architects of innovation, where creativity meets functionality and vision meets reality.
                                </p>
                                <p>
                                    With a team of passionate designers, developers, and digital strategists, we are dedicated to transforming your online
                                    presence into an engaging narrative that captivates your audience.

                                </p>
                                <a onClick={handleClickOpen} className="butn butn-bg">
                                    <span>Buy Now</span>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="image">
                                <img src="/img/intro.jpg" alt="" />
                                {/* <a className="vid icon" href="#" target="_blank">
                                <span className="vid-butn ti-control-forward"></span>
                            </a> */}
                            </div>
                        </div>

                        <div className="skills col-sm-12 mt-50">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="skill-item">
                                        <h5>Creativity & Innovation</h5>
                                        <div className="skill-progress">
                                            <div className="progres" data-value="90%"></div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h5>Integrity & Transparency</h5>
                                        <div className="skill-progress">
                                            <div className="progres" data-value="80%"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="skill-item">
                                        <h5>Collaboration</h5>
                                        <div className="skill-progress">
                                            <div className="progres" data-value="85%"></div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h5>Client-Centric Method</h5>
                                        <div className="skill-progress">
                                            <div className="progres" data-value="75%"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <ThemeProvider theme={theme}>
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>Buy Our Premium Membership</DialogTitle>
                    <DialogContent className='d-flex flex-column-reverse flex-lg-row' style={{gap: '1rem'}}>
                        <DialogContentText style={{ width: '100%' }}>
                            Join Our Premium Membership for 2 year.
                            <h5>Features:</h5>
                            <ul style={{ padding: '0 20px' }}>
                                <li style={{ listStyle: 'circle' }}>Digital Documents</li>
                                <li style={{ listStyle: 'circle' }}>Stamped Documents</li>
                                <li style={{ listStyle: 'circle' }}>All Legal Paperwork</li>
                                <li style={{ listStyle: 'circle' }}>Digital Invoices</li>
                                <li style={{ listStyle: 'circle' }}>Access to Multiple Products</li>
                                <li style={{ listStyle: 'circle' }}>Customer Support</li>

                            </ul>
                            <h6>Price: 7,500.00 AED</h6>
                            {/* <a href="#" className="butn butn-bg mt-10">
                                <span>Buy Now</span>
                            </a> */}
                            <div className='d-flex flex-column mt-20  col-sm-12 col-lg-6' style={{ gap: '0.5rem' }}>
                                <button type="button" className="btn btn-outline-info d-flex align-items-center justify-content-center buttonPay" style={{ gap: '0.5rem' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width={25} height={25} viewBox="0 0 472.001 472.001" className='payWithCard'>
                                        <g>
                                            <path d="M431.313,81.376H40.688C18.252,81.376,0,99.628,0,122.063v227.875c0,22.436,18.252,40.688,40.688,40.688h390.625
                                                    c22.436,0,40.688-18.252,40.688-40.688V122.063C472.001,99.627,453.749,81.376,431.313,81.376z M40.688,93.376h390.625
                                                    c15.817,0,28.688,12.869,28.688,28.687v25.734h-448v-25.734C12.001,106.245,24.87,93.376,40.688,93.376z M431.313,378.625H40.688
                                                    C24.87,378.625,12,365.756,12,349.938V176.796h448v173.142C460.001,365.756,447.132,378.625,431.313,378.625z"/>
                                            <path d="M143.369,243.591H73.344C60.472,243.591,50,254.063,50,266.935v27.75c0,12.871,10.472,23.344,23.344,23.344h70.025
                                                    c12.872,0,23.344-10.473,23.344-23.344v-27.75C166.713,254.063,156.241,243.591,143.369,243.591z M154.713,294.685
                                                    c0,6.254-5.089,11.344-11.344,11.344H73.344c-6.255,0-11.344-5.09-11.344-11.344v-27.75c0-6.254,5.089-11.344,11.344-11.344
                                                    h70.025c6.255,0,11.344,5.09,11.344,11.344V294.685L154.713,294.685z"/>
                                            <path d="M417.456,243.591H222.195c-3.313,0-6,2.688-6,6s2.687,6,6,6h195.261c3.312,0,6-2.688,6-6S420.771,243.591,417.456,243.591
                                                    z"/>
                                            <path d="M315.995,290.418h-93.8c-3.313,0-6,2.687-6,6s2.687,6,6,6h93.8c3.312,0,6-2.687,6-6S319.309,290.418,315.995,290.418z" />
                                        </g>
                                    </svg>
                                    Pay with Card
                                </button>
                                <button type="button" className="btn btn-outline-dark d-flex align-items-center justify-content-center buttonPay" style={{ gap: '0.5rem' }}>
                                    <svg width={25} height={25} viewBox="0 0 24 24" className='payWithApple' xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.52 12.46C16.508 11.8438 16.6682 11.2365 16.9827 10.7065C17.2972 10.1765 17.7534 9.74476 18.3 9.46C17.9558 8.98143 17.5063 8.5883 16.9862 8.31089C16.466 8.03349 15.8892 7.87923 15.3 7.86C14.03 7.76 12.65 8.6 12.14 8.6C11.63 8.6 10.37 7.9 9.40999 7.9C7.40999 7.9 5.29999 9.49 5.29999 12.66C5.30963 13.6481 5.48194 14.6279 5.80999 15.56C6.24999 16.84 7.89999 20.05 9.61999 20C10.52 20 11.16 19.36 12.33 19.36C13.5 19.36 14.05 20 15.06 20C16.79 20 18.29 17.05 18.72 15.74C18.0689 15.4737 17.5119 15.0195 17.1201 14.4353C16.7282 13.8511 16.5193 13.1634 16.52 12.46ZM14.52 6.59C14.8307 6.23965 15.065 5.82839 15.2079 5.38245C15.3508 4.93651 15.3992 4.46569 15.35 4C14.4163 4.10239 13.5539 4.54785 12.93 5.25C12.6074 5.58991 12.3583 5.99266 12.1983 6.43312C12.0383 6.87358 11.9708 7.34229 12 7.81C12.4842 7.82361 12.9646 7.71974 13.3999 7.50728C13.8353 7.29482 14.2127 6.98009 14.5 6.59H14.52Z"/>
                                    </svg>
                                    Apple Pay
                                    
                                </button>
                                <button type="button" className="btn btn-outline-success d-flex align-items-center justify-content-center buttonPay" style={{ gap: '0.5rem' }}>
                                    <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
                                        className='payWithTabby'
                                    >
                                        
                                        <path d="M181.86,138.93c-1.62.21-3.18.37-4.8.53V9.64h95.62V42.82C268.53,91.47,230.93,132.19,181.86,138.93Z"/>
                                        <path d="M383.54,175.18l-118,18.42L266,225l117.54-18.37v64.64L266.91,289.49l1.14,79.89c0,35,46.48,33,56.56,33,23.59,0,45.46-11.79,45.46-11.79v85.54l-31.83,8.67a145.64,145.64,0,0,1-25.91,4.74c-105,8.89-138.88-53-138.88-110.37v-75l-57,8.89V248.39l57-8.89V208l-57,8.89V149.16l65.4-10.23,201.68-31.52Z"/>
                                    </svg>
                                    Pay with Tabby
                                </button>
                            </div>
                        </DialogContentText>
                        <DialogContentText style={{ width: '100%' }}>
                            <img src="/img/intro.jpg" alt="Premium Membership" className='rounded' />
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </>
    )
}

export default Product