import React from 'react'

const Whyus = () => {
  return (
    <section className="services section-padding bg-gray" data-scroll-index="2">
            <div className="container">
                <div className="row">

                    <div className="section-head text-center mb-70 col-sm-12">
                        <h4>Why Choose Us?</h4>
                    </div>

                    <div className="tabs-icon mb-40 col-lg-8 offset-lg-2 col-md-10 offset-md-1 text-center">
                        <div className="owl-carousel owl-theme">
                            <div id="tab-1" className="active item">
                                <span className="icon ti-desktop"></span>
                                <h6>Design</h6>
                            </div>
                            <div id="tab-2" className="item">
                                <span className="icon ti-shield"></span>
                                <h6>Solutions</h6>
                            </div>
                            <div id="tab-3" className="item">
                                <span className="icon ti-infinite"></span>
                                <h6>Record</h6>
                            </div>
                            <div id="tab-4" className="item">
                                <span className="icon ti-palette"></span>
                                <h6>Approach</h6>
                            </div>
                            <div id="tab-5" className="item">
                                <span className="icon ti-stats-up"></span>
                                <h6>Support</h6>
                            </div>
                            <div id="tab-6" className="item">
                                <span className="icon ti-stats-up"></span>
                                <h6>Creativity</h6>
                            </div>
                        </div>
                    </div>

                    <div className="services-content col-sm-12">

                        <div id="tab-1-content" className="cont active">

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="services-info">
                                        <h5>Design</h5>
                                        <h3>Exceptional Design Expertise</h3>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="serv-cont">
                                        <p className="mb-10">
                                            Our team comprises skilled designers who transform ideas into visually stunning designs. We create websites that look beautiful and provide seamless user experiences.
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div id="tab-2-content" className="cont">

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="services-info">
                                        <h5>Solutions</h5>
                                        <h3>
                                            Tailored Solutions
                                        </h3>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="serv-cont">
                                        <p className="mb-10">
                                            We understand that every business is unique. Our solutions are customer-tailored to meet the specific requirements of your brand, ensuring a one-of-a-kind digital presence.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div id="tab-3-content" className="cont">

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="services-info">
                                        <h5>Record</h5>
                                        <h3>Proven Track Record</h3>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="serv-cont">
                                        <p className="mb-10">
                                            With a portfolio of successful projects and satisfied clients, we have a proven track record of delivering high-quality digital solutions that drive results.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div id="tab-4-content" className="cont">

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="services-info">
                                        <h5>Approach</h5>
                                        <h3>Results Driven Approach</h3>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="serv-cont">
                                        <p className="mb-10">
                                            We are driven by results. Our strategies are meticulously planned and executed to achieve measurable outcomes, whether it's increased traffic, higher conversions, or enhanced user engagement.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div id="tab-5-content" className="cont">

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="services-info">
                                        <h5>Support</h5>
                                        <h3>
                                            Exceptional Customer Support
                                        </h3>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="serv-cont">
                                        <p className="mb-10">
                                            We believe in providing exceptional customer support. Our dedicated team is always available to address your queries, concerns, and technical issues, ensuring a smooth and hassle-free experience.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div id="tab-6-content" className="cont">

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="services-info">
                                        <h5>Creativity</h5>
                                        <h3>Creativity, Expertise & Dedication</h3>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="serv-cont">
                                        <p className="mb-10">
                                            At J. Marketing Services, we don't just build websites; we craft digital masterpieces that elevate your brand to new heights.
                                        </p>
                                        <p>
                                            If you're looking for a partner that combines creativity, expertise and dedication to transform your online presence, choose J. MARKETING SERVICES and embark on a digital journey that leads to supreme success.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>
        </section>
  )
}

export default Whyus