import React from 'react'
import Header from '../components/Header'

const TermsConditions = () => {
  return (
    <>
    <Header heading={'Terms and Conditions'} />
    <section className="hero bg-gray" data-scroll-index="1" style={{padding: '20px 0'}}>
        <div className="container">
            <div className="row">

                <div className="offset-lg-1 col-lg-10">

                    <div className="intro text-center mb-80">
                        <div>

                            <p className="mb-10">
                                Welcome to J. Marketing Services via Social Media EST. These terms and conditions outline the rules and regulations for the use of our services.
                                <br />
                                By accessing this website and using our services, we assume you accept these terms and conditions. Do not continue to use J. Marketing Services via Social Media EST if you do not agree to all of the terms and conditions stated on this page.
                            </p>
                            <h6 className="mb-10 text-left">1. Interpretation and Definitions</h6>

                            <div className="text-left">
                                <strong>1.1. Interpretation:</strong> The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                            <br />
                            <br />
                                <strong>1.2. Definitions:</strong>

                            <br />
                            <ul className="text-left">
                                <li style={{listStyle: "circle"}}>"Client", "You", and "Your" refer to you, the person accessing this website and accepting the Company's terms and conditions.</li>
                                <li style={{listStyle: "circle"}}>"The Company," "Ourselves," "We," "Our," and "Us," refer to J. Marketing Services via Social Media EST.</li>
                                <li style={{listStyle: "circle"}}>"Party," "Parties," or "Us," refers to both the Client and ourselves, or either the Client or ourselves.</li>
                                <li style={{listStyle: "circle"}}>"Services" refer to the services provided by J. Marketing Services via Social Media EST.</li>
                                <li style={{listStyle: "circle"}}>"Website" refers to J. Marketing Services via Social Media EST, accessible from https://jdotservices.info.
                                </li>

                            </ul>
                            </div>
                            <p></p>
                            <br />
                            <h6 className="text-left">2. Scope of Services</h6>

                            <br />
                            <div className="text-left">
                                <strong>2.1. Description:</strong> J. Marketing Services via Social Media EST offers marketing services via various social media platforms. These services may include but are not limited to content creation, social media management, advertising campaigns, and analytics reporting.
                                <br />
                                <br />
                                <strong>2.2. Agreement:</strong> By engaging our services, you agree to abide by the terms and conditions laid out in this document and any additional agreements made between you and J. Marketing Services via Social Media EST.
                            </div>
                            <br />
                            <div className="text-left">
                                <h6 className="mb-10 text-left">3. Fees and Payments</h6>
                                <strong>3.1. Pricing:</strong> The pricing for our services is outlined in our proposal or contract provided to you upon request.
                                    <br />
                                    <br />
                                    <strong>3.2. Payment:</strong> Payment for services rendered is due as per the terms specified in the proposal or contract. Failure to make payments on time may result in suspension or termination of services.
                            </div>
                            <p></p>
                            <br />
                            <h6 className="mb-10 text-left">4. Intellectual Property</h6>
                            <div className="text-left">
                                <strong>4.1. Ownership:</strong> All intellectual property rights, including copyrights, trademarks, and any other rights related to our services, remain the property of J. Marketing Services via Social Media EST unless otherwise agreed upon in writing.
                            </div>
                            <br />
                            <div className="text-left">
                                <h6 className="mb-10 text-left">5. Confidentiality</h6>
                                <strong>5.1. Non-Disclosure:</strong> Both parties agree to keep confidential any proprietary or sensitive information shared during the course of the engagement.
                            </div>
                            <p></p>
                            <br />
                            <h6 className="mb-10 text-left">6. Limitation of Liability</h6>
                            <div className="text-left">
                                <strong>6.1. Exclusion:</strong> In no event shall J. Marketing Services via Social Media EST, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of our services.
                            </div>
                            <div className="text-left">
                                <br />
                                <h6 className="mb-10 text-left">7. Governing Law</h6>
                                <strong>7.1. Jurisdiction:</strong> These terms and conditions shall be governed by and construed in accordance with the laws of UAE Jurisdiction, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of UAE Jurisdiction.
                            </div>
                            <p></p>
                            <br />
                            <h6 className="mb-10 text-left">8. Changes to Terms and Conditions</h6>
                            <div className="text-left">
                                <strong>8.1. Amendment:</strong> J. Marketing Services via Social Media EST reserves the right to amend these terms and conditions at any time. We will notify you of any changes by posting the new terms and conditions on this page.
                                <br />
                                <br />
                                <strong>8.2. Acceptance of Changes:</strong> Your continued use of our services after any revisions to these terms and conditions will constitute your acceptance of such changes.
                            </div>
                            <br />
                            <p>
                                By engaging our services, you acknowledge that you have read and understood these terms and conditions and agree to be bound by them.
                                <br />
                                If you have any questions about these terms and conditions, please contact us through our contact channels.
                            </p>

                        </div>
                    </div>

                </div>


            </div>
        </div>
    </section>
    </>
  )
}

export default TermsConditions