import React from 'react'
import Nav from './components/Nav';
import Loader from './components/Loader';
import Home from './components/Home';
import Footer from './components/Footer';


const Layout = ({children}) => {
    return (
        <div>
            {/* <Loader /> */}
            <Nav />
            {/* <Home /> */}
            {children}
            <Footer />
        </div>
    )
}

export default Layout