import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./Layout";
import Home from "./components/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import RefundPolicy from "./pages/RefundPolicy";
import StaticLayout from "./components/StaticLayout";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout children={<Home />} />} />

          <Route path="privacy-policy" element={<StaticLayout children={<PrivacyPolicy />} />} />
          <Route path="terms-conditions" element={<StaticLayout children={<TermsConditions />} />} />
          <Route path="refund-policy" element={<StaticLayout children={<RefundPolicy />} />} />
          
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
