import React from 'react'

const Hero = () => {
    return (
        <header className="header valign bg-img" data-scroll-index="0" data-overlay-dark="5" data-background="/img/bg1.jpg" data-stellar-background-ratio="0.5">

            <div className="container">
                <div className="row">
                    <div className="full-width text-center caption mt-30">
                        <h4>J. Marketing Services</h4>
                        <h1>Your Business Solutions</h1>
                        <p>Being a one-stop-solution Digital Agency, we go beyond conventional digital solutions.</p>
                        <p>We create digital masterpieces that resonate with your target audience, driving conversions and boosting your online visibility.</p>
                        <a href="#" data-scroll-nav="1" className="butn butn-light mt-30">
                            <span>Learn More</span>
                        </a>
                        <a href="#" data-scroll-nav="6" className="butn butn-bg mt-30">
                            <span>Get Started</span>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Hero