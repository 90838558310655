import React from 'react'

const Contact = () => {
  return (
    <section className="contact section-padding" data-scroll-index="6">
            <div className="container">
                <div className="row">

                    <div className="section-head text-center col-sm-12">
                        <h4>Get In Touch</h4>
                    </div>

                    <div className="info col-lg-12 mb-80">
                        <div className="row">
                            
                            <div className="col-lg-4">
                                <div className="item mb-md30">
                                    <div className="img-overlay bg-img" data-overlay-dark="8" data-background="/img/4.jpg"></div>
                                    <span className="icon ti-mobile"></span>
                                    <div className="cont">
                                        <h6>Phone : </h6>
                                        <p>+971 50 6881325</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="item mb-md30">
                                    <div className="img-overlay bg-img" data-overlay-dark="8" data-background="/img/2.jpg"></div>
                                    <span className="icon ti-email"></span>
                                    <div className="cont">
                                        <h6>Email : </h6>
                                        <p>infoatjmarketing@gmail.com</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="item">
                                    <div className="img-overlay bg-img" data-overlay-dark="8" data-background="/img/3.jpg"></div>
                                    <span className="icon ti-location-arrow"></span>
                                    <div className="cont">
                                        <h6>Address : </h6>
                                        <p>Happy Land Building - Office no. 103 - Muteena - Dubai</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    {/* <div className="col-md-12">
                        <form className="form" method="post" action="#">
                         
                            <div className="messages"></div>

                            <div className="controls">

                                <div className="row">

                                    <div className="col-lg-5">
                                        <div className="form-group">
                                            <input id="form_name" type="text" name="name" placeholder="Name *" required="required" data-error="Firstname is required." />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="form-group">
                                            <input id="form_email" type="email" name="email" placeholder="Email *" required="required" data-error="Valid email is required." />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="form-group">
                                            <input id="form_subject" type="text" name="phone" placeholder="Phone *" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="form-group">
                                            <textarea id="form_message" name="message" placeholder="Your Message *" rows="4" required="required" data-error="Your message is required."></textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="butn butn-bg"><span>Send Message</span></button>
                                    </div>

                                </div>                             
                            </div>
                        </form>
                    </div> */}

                </div>
            </div>
        </section>
  )
}

export default Contact