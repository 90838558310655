import React from 'react'
import Header from '../components/Header'

const PrivacyPolicy = () => {
    return (
        <>
            <Header heading={'Privacy Policy'} />
            <section className="hero bg-gray" data-scroll-index="1" style={{padding: '20px 0'}}>
                <div className="container">
                    <div className="row">

                        <div className="offset-lg-1 col-lg-10">

                            <div className="intro text-center mb-80">
                                <div>

                                    <p className="mb-10">
                                        J. Marketing Services via Social Media EST. operates the website
                                        https://jdotservices.info. This page informs you of our policies regarding the
                                        collection, use, and disclosure of personal data when you use our service and the
                                        choices you have associated with that data.
                                        <br />
                                        We use your data to provide and improve the Service. By using the Service, you agree to
                                        the collection and use of information in accordance with this policy.
                                    </p>
                                    <h6 className="mb-10 text-left">Information Collection and Use</h6>

                                    <div className="text-left">
                                        We collect several different types of information for various purposes to provide and
                                        improve our Service to you.
                                    </div>

                                    <br />
                                    <div className="text-left">
                                        <h6 className="text-left">Types of Data Collected</h6>

                                        <br />
                                        <ul className="text-left">
                                            <li style={{ listStyle: "circle" }}>Personal Data: While using our Service, we may ask
                                                you to provide us with certain personally identifiable information that can be used
                                                to contact or identify you. Personally identifiable information may include, but is
                                                not limited to:</li>
                                            <li style={{ listStyle: "circle" }}>Email Address</li>
                                            <li style={{ listStyle: "circle" }}>First name and last name</li>
                                            <li style={{ listStyle: "circle" }}>Phone number</li>
                                            <li style={{ listStyle: "circle" }}>Address, State, Province, ZIP/Postal code, City
                                            </li>
                                            <li style={{ listStyle: "circle" }}>Cookies and Usage Data</li>

                                        </ul>
                                    </div>
                                    <p></p>
                                    <br />
                                    <h6 className="text-left">We use the collected data for various purposes:</h6>

                                    <br />
                                    <ul className="text-left">
                                        <li style={{ listStyle: "circle" }}>To provide and maintain our Service</li>
                                        <li style={{ listStyle: "circle" }}>To notify you about changes to our Service</li>
                                        <li style={{ listStyle: "circle" }}>To allow you to participate in interactive features
                                            of our Service when you choose to do so</li>
                                        <li style={{ listStyle: "circle" }}>To provide customer support</li>
                                        <li style={{ listStyle: "circle" }}>To gather analysis or valuable information so that
                                            we can improve our Service
                                        </li>
                                        <li style={{ listStyle: "circle" }}>To monitor the usage of our Service</li>
                                        <li style={{ listStyle: "circle" }}>To detect, prevent and address technical issues
                                        </li>

                                    </ul>
                                    <br />
                                    <div className="text-left">
                                        <h6 className="mb-10 text-left">Transfer of Data</h6>
                                        Your information, including Personal Data, may be transferred to — and maintained on —
                                        computers located outside of your state, province, country, or other governmental
                                        jurisdiction where the data protection laws may differ from those of your jurisdiction.
                                        <br />
                                        If you are located outside United Arab Emirates and choose to provide information to us,
                                        please note that we transfer the data, including Personal Data, to United Arab Emirates
                                        and
                                        process it there.
                                    </div>
                                    <p></p>
                                    <br />
                                    <h6 className="text-left">Disclosure of Data</h6>
                                    <div className="text-left">We may disclose your Personal Data in the good faith belief
                                        that such action is necessary to:</div>
                                    <br />
                                    <ul className="text-left">
                                        <li style={{ listStyle: "circle" }}>To comply with a legal obligation</li>
                                        <li style={{ listStyle: "circle" }}>To protect and defend the rights or property of J.
                                            Marketing Services via Social Media EST.
                                        </li>
                                        <li style={{ listStyle: "circle" }}>To prevent or investigate possible wrongdoing in
                                            connection with the Service</li>
                                        <li style={{ listStyle: "circle" }}>To protect the personal safety of users of the
                                            Service or the public</li>
                                        <li style={{ listStyle: "circle" }}>To protect against legal liability
                                        </li>

                                    </ul>
                                    <br />
                                    <div className="text-left">
                                        <h6 className="mb-10 text-left">Security of Data</h6>
                                        In Short: We keep your information for as long as necessary to fulfill the purposes
                                        outlined in this privacy policy unless otherwise required by law.
                                        <br />
                                        <br />
                                        We will only keep your personal information for as long as it is necessary for the
                                        purposes set out in this privacy policy, unless a longer retention period is required or
                                        permitted by law (such as tax, accounting or other legal requirements). No purpose in
                                        this policy will require us keeping your personal information for longer than a week.
                                        <br />
                                        <br />
                                        When we have no ongoing legitimate business need to process your personal information,
                                        we will either delete or anonymize it, or, if this is not possible (for example, because
                                        your personal information has been stored in backup archives), then we will securely
                                        store your personal information and isolate it from any further processing until
                                        deletion is occured.
                                    </div>
                                    <p></p>
                                    <br />
                                    <h6 className="mb-10 text-left">Cookies</h6>
                                    <div className="text-left">
                                        A cookie is a small amount of information that's downloaded to your computer or device
                                        when you visit our website. We use a number of different cookies, including functional,
                                        performance, advertising, and social media and content cookies.
                                        <br />
                                        <br />
                                        Cookies make your browsing experience better by allowing the website to remember your
                                        actions and preferences. This means you don't have to re-enter this information each
                                        time you return to the site or browse from one page to another. Cookies also provide
                                        information on how people use the website, for instance whether it's their first time
                                        visiting or if they are a frequent visitor.
                                        <br />
                                        <br />
                                        You can control and manage cookeis in various ways. Please keep in mind that removing or
                                        blocking cookies can negatively impact your user experience and parts of our website may
                                        no longer be fully accessible.
                                    </div>
                                    <div className="text-left">
                                        <br />
                                        <h6 className="mb-10 text-left">Contact</h6>
                                        For more information about our privacy practices, if you have questions, or if you would
                                        like to make a complaint, please contact us through our respective channels by mail or
                                        by using the details provided in the contact sections.
                                    </div>
                                    <p></p>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy