import React from 'react'
import { Outlet, Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="text-center">
                <div className="container">

                    <a className="logo" href="/">
                        <img src="/img/logo-light.png" alt="logo" />
                    </a>

                    <div className="mt-10 mb-10 text-white d-flex justify-content-center flex-column flex-lg-row" style={{gap: '1rem'}}>
                        <Link to="/privacy-policy" className='border-right' style={{padding: '0 10px 0 0'}}>
                            <p>Privacy Policy</p>
                        </Link>
                        <Link to="/terms-conditions" className='border-right' style={{padding: '0 10px 0 0'}}>
                            <p>Terms & Conditions</p>
                        </Link>
                        <Link to="/refund-policy">
                            <p>Refund Policy</p>
                        </Link>
                    </div>

                    <p>&copy; 2024 J. Marketing Services via Social Media EST. All Rights Reserved.</p>

                </div>
            </footer>

            <a href="#" data-scroll-nav="0" className="scrollUp"> <span>Go Top</span> </a>
        </>
    )
}

export default Footer