import React from 'react'
import Header from '../components/Header'

const RefundPolicy = () => {
    return (
        <>
            <Header heading={'Refund Policy'} />
            <section className="hero bg-gray" data-scroll-index="1" style={{ padding: '20px 0' }}>
                <div className="container">
                    <div className="row">

                        <div className="offset-lg-1 col-lg-10">

                            <div className="intro text-center mb-80">
                                <div>

                                    <p className="mb-10">
                                        Thank you for choosing J. Marketing Services via Social Media EST for your marketing
                                        needs. We understand that circumstances may arise where you may need to request a
                                        refund. Please read our refund policy carefully to understand your rights and
                                        obligations regarding refunds.
                                    </p>
                                    <h6 className="mb-10 text-left">1. Eligibility for Refunds</h6>

                                    <div className="text-left">
                                        <strong>1.1. Services Rendered:</strong> Refunds are only applicable to services that
                                        have not been rendered or completed. Once services have been provided, refunds may not
                                        be available, except as outlined in Section 2 (Cancellation).
                                        <br />
                                        <br />
                                        <strong>1.2. Satisfaction Guarantee:</strong> We strive to provide high-quality services
                                        to our clients. If you are not satisfied with our services, please contact us within
                                        7 days of service delivery to discuss your concerns.
                                    </div>
                                    <br />
                                    <div className="text-left">
                                        <h6 className="text-left">2. Cancellation</h6>

                                        <br />
                                        <strong>2.1. Client Request:</strong> If you wish to cancel services and request a
                                        refund, you must notify us in writing at least 2 days prior to the scheduled start date
                                        of the service. A cancellation fee may apply, as outlined in your contract or proposal.
                                        <br />
                                        <br />
                                        <strong>2.2. Company Discretion:</strong> J. Marketing Services via Social Media EST
                                        reserves the right to cancel services and issue a refund at our discretion.
                                        Circumstances under which we may cancel services include but are not limited to:
                                        non-compliance with our terms and conditions, failure to provide necessary information
                                        or materials, or inability to fulfill the agreed-upon services.
                                    </div>
                                    <p></p>

                                    <br />
                                    <h6 className="mb-10 text-left">3. Refund Process</h6>
                                    <div className="text-left">
                                        <strong>3.1. Evaluation:</strong> All refund requests will be evaluated on a case-by-case basis. We may request additional information or documentation to process your refund request.
                                        <br />
                                        <br />
                                        <strong>3.2. Approval:</strong> If your refund request is approved, we will initiate the refund process within 7 working days of approval. Refunds will be issued via the original method of payment.
                                        <br />
                                        <br />
                                        <strong>3.3. Deductions:</strong> Refunds may be subject to deductions for any applicable cancellation fees or unrecoverable expenses incurred by J. Marketing Services via Social Media EST.
                                    </div>
                                    <br />
                                    <div className="text-left">
                                        <h6 className="mb-10 text-left">4. Contact Us</h6>
                                        If you have any questions about our refund policy or wish to request a refund, please contact us through our contact us section. Our team will be happy to assist you and address any concerns you may have.
                                    </div>
                                    <p></p>

                                    <br />
                                    <h6 className="mb-10 text-left">5. Changes to Refund Policy</h6>
                                    <div className="text-left">
                                        J. Marketing Services via Social Media EST reserves the right to amend this refund policy at any time. Any changes to the refund policy will be posted on this page.
                                    </div>
                                    <br />
                                    <p>
                                        By engaging our services, you acknowledge that you have read and understood our refund policy and agree to be bound by its terms.
                                    </p>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default RefundPolicy