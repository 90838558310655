import React from 'react'
import Hero from './../components/Hero';
import WhatWeDo from './../components/WhatWeDo';
import Product from './../components/Product';
import Whyus from './../components/Whyus';
import Services from './../components/Services';
import Portfolio from './../components/Portfolio';
import Customers from './../components/Customers';
import Contact from './../components/Contact';
import Footer from './../components/Footer';

const Home = () => {
    return (
        <div>
            <Hero />
            <WhatWeDo />
            <Product />
            <Whyus />
            <Services />
            <Portfolio />
            <Customers />
            <Contact />
            {/* <Footer /> */}
        </div>
    )
}

export default Home