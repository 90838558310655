import React from 'react'
import Footer from './Footer'
import { Outlet, Link } from "react-router-dom";

const StaticLayout = ({children}) => {
    return (
        <>
        <nav className="navbar navbar-expand-lg">
            <div className="container">

                <a className="logo" href="/">
                    <img src="/img/logo-light.png" alt="logo" />
                </a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"><i className="fas fa-bars"></i></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        {children}
        <Footer />
        </>
    )
}

export default StaticLayout