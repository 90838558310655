import React from 'react'

const Customers = () => {
  return (
    <>
    <div className="numbers bg-img bg-fixed" data-overlay-dark="9" data-background="/img/01.jpg">
            <div className="container">
                <div className="row">

                    <div className="col-lg-3 col-md-6">
                        <div className="item text-center mb-md50">
                            <span className="icon ti-heart"></span>
                            <h4 className="count">368</h4>
                            <h6>Happy Clients</h6>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="item text-center mb-md50">
                            <span className="icon ti-layers-alt"></span>
                            <h4 className="count">937</h4>
                            <h6>Marketing Projects</h6>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="item text-center mb-sm50">
                            <span className="icon ti-import"></span>
                            <h4 className="count">90</h4>
                            <h6>Development Projects</h6>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="item text-center">
                            <span className="icon ti-shield"></span>
                            <h4 className="count">5946</h4>
                            <h6>Designing Projects</h6>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div className="clients pt-50 pb-50">
            <div className="container">
                <div className="row">
                    
                    <div className="owl-carousel owl-theme">
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/1.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/2.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/3.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/4.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/5.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/6.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/7.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/8.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/9.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/10.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/11.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/12.png" alt="" />
                            </a>
                        </div>
                        <div className="item">
                            <a href="#0">
                                <img src="/img/clients-logo/13.png" alt="" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <section className="testimonials carousel-single section-padding bg-img bg-fixed" data-overlay-dark="6" data-background="/img/02.jpg">
            <div className="container">
                <div className="row">

                    <div className="owl-carousel owl-theme offset-lg-1 col-lg-10 text-center">
                        <div className="item">
                            <span className="icon ti-quote-left"></span>
                            <p>
                                I'm so happy that I chose my first Marketing Agency with J. Marketing. They helped me in every Designing and Development Steps, including graphical experiences as well as marketing. Their team is highly qualified.
                            </p>
                            <h6>Muhammad Bin Sallah</h6>
                            <h5>Foreman of Etisalat</h5>
                        </div>
                        <div className="item">
                            <span className="icon ti-quote-left"></span>
                            <p>
                                Very grateful to your! You people assisted me in establishing my business and growing in Dubai and handled all my projects very professionally and honestly. You have become my favorite Agency.
                            </p>
                            <h6>Abdallah Mostafa</h6>
                            <h5>CEO Founder of Trendy Company</h5>
                        </div>
                        <div className="item">
                            <span className="icon ti-quote-left"></span>
                            <p>
                                J. Marketing team, you are really amazing. They helped me and my friend through the entire business development and architect for business design and made sure it was all.
                            </p>
                            <h6>Ehsan ahmad</h6>
                            <h5>Manager of Mars Communications</h5>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default Customers