import React from 'react'

const Portfolio = () => {
  return (
    <section className="works section-padding" data-scroll-index="3">
            <div className="container">
                <div className="row">
                    
                    <div className="section-head text-center col-sm-12">
                        <h4>Our Portfolio</h4>
                    </div>

                    <div className="clearfix"></div>

                    <div className="gallery text-center full-width">


                        <div className="col-lg-3 col-md-6 items web">
                            <div className="item-img">
                                <img src="/img/portfolio/doc1.jpg" alt="image" />
                                <div className="item-img-overlay valign">
                                    <div className="overlay-info full-width vertical-center">
                                        <p>All Services</p>
                                        <h6>PulseWorld</h6>
                                        <a href="/img/portfolio/doc1.jpg" className="popimg">
                                            <span className="icon ti-zoom-in"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 items web">
                            <div className="item-img">
                                <img src="/img/portfolio/doc2.jpg" alt="image" />
                                <div className="item-img-overlay valign">
                                    <div className="overlay-info full-width vertical-center">
                                        <p>UI/UX, Web Development</p>
                                        <h6>The Right Strategies</h6>
                                        <a href="/img/portfolio/doc2.jpg" className="popimg">
                                            <span className="icon ti-zoom-in"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 items graphic">
                            <div className="item-img">
                                <img src="/img/portfolio/family.jpg" alt="image" />
                                <div className="item-img-overlay valign">
                                    <div className="overlay-info full-width vertical-center">
                                        <p>UI/UX, Web Development, SEO</p>
                                        <h6>SKILLAMI</h6>
                                        <a href="/img/portfolio/family.jpg" className="popimg">
                                            <span className="icon ti-zoom-in"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 items graphic">
                            <div className="item-img">
                                <img src="/img/portfolio/freelance.jpg" alt="image" />
                                <div className="item-img-overlay valign">
                                    <div className="overlay-info full-width vertical-center">
                                        <p>All Services</p>
                                        <h6>QuickDropX - Drop Shipping</h6>
                                        <a href="/img/portfolio/freelance.jpg" className="popimg">
                                            <span className="icon ti-zoom-in"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 items brand">
                            <div className="item-img">
                                <img src="/img/portfolio/dubai1.jpg" alt="image" />
                                <div className="item-img-overlay valign">
                                    <div className="overlay-info full-width vertical-center">
                                        <p>Digital Marketing Services</p>
                                        <h6>BillionariosPro8</h6>
                                        <a href="/img/portfolio/dubai1.jpg" className="popimg">
                                            <span className="icon ti-zoom-in"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 items brand">
                            <div className="item-img">
                                <img src="/img/portfolio/dubai2.jpg" alt="image" />
                                <div className="item-img-overlay valign">
                                    <div className="overlay-info full-width">
                                        <p>All Services</p>
                                        <h6>Cleanshop.Net</h6>
                                        <a href="/img/portfolio/dubai2.jpg" className="popimg">
                                            <span className="icon ti-zoom-in"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>

                </div>
            </div>
        </section>
  )
}

export default Portfolio